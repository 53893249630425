export function sectorsHover() {
	$(".sector_btns .btn").on("mouseover", function (event) {
		var $imageToggleID = "#toggle-" + $(this).data("image");

		$(".sector_btns .btn").removeClass("active");
		$(".sectorImage").removeClass("active");

		$(this).addClass("active");
		$($imageToggleID).addClass("active");
	});
}
