import { initCtaFunctions, productReadMore, contactToggle, displayFormToggle } from './common/ctaFunctions';
import { navigation, stickyNav, navigationFunctions } from './common/nav';
import { iframeLazyLoad } from './common/lazyload';

import { searchFilters } from './common/searchFilter';

import { homepageFormVisible } from './modules/forms';

import { sectorsHover } from './modules/sectorsHover';

import { resourceZip } from './modules/resources';

import { initHeroSlider } from './modules/heroSlider';
import { productSlider } from './modules/sliders';

// jQuery doc ready
$(function () {
	initCtaFunctions();
	stickyNav();
	searchFilters();
	iframeLazyLoad();
	initHeroSlider();
	productSlider();
	productReadMore();
	contactToggle();
	homepageFormVisible();
	sectorsHover();
	displayFormToggle();
	resourceZip();

	navigationFunctions(navigation);
});

window.onscroll = function () {
	stickyNav();
	homepageFormVisible();
};
