export function resourceZip() {
	var checked = 0;
	var Promise = window.Promise;
	var resultMessage = $("#result");
	var progress_bar = $("#progress_bar");

	function getChecked() {
		checked = $("#download_form").find(":checked");
		$("#selected").text(checked.length);
	}

	function resetMessage() {
		$(resultMessage).removeClass().text("");
	}

	function showMessage(text) {
		resetMessage();
		$(resultMessage).addClass("alert alert-success").text(text);
	}

	function showError(text) {
		resetMessage();
		$(resultMessage).addClass("alert alert-danger").text(text);
	}

	function updatePercent(percent) {
		$(progress_bar)
			.removeClass("hide")
			.find(".progress-bar")
			.attr("aria-valuenow", percent)
			.css({ width: percent + "%" });
	}
	if (!Promise) {
		Promise = JSZip.external.Promise;
	}

	function urlToPromise(url) {
		return new Promise(function (resolve, reject) {
			JSZipUtils.getBinaryContent(url, function (err, data) {
				if (err) {
					reject(err);
				} else {
					resolve(data);
				}
			});
		});
	}

	var $form = $("#download_form").on("submit", function () {
		resetMessage();
		var zip = new JSZip();

		if (checked.length > 0) {
			// find every checked item
			checked.each(function () {
				var $this = $(this);
				var url = $this.data("url");
				var filename = url.replace(/.*\//g, "");
				zip.file(filename, urlToPromise(url), {
					binary: true,
				});
			});

			// when everything has been downloaded, we can trigger the dl
			zip.generateAsync(
				{
					type: "blob",
				},
				function updateCallback(metadata) {
					var msg = "progression : " + metadata.percent.toFixed(2) + " %";
					showMessage(msg);
					updatePercent(metadata.percent | 0);
				}
			).then(
				function callback(blob) {
					saveAs(blob, "download.zip");

					showMessage("Download Complete");
				},
				function (e) {
					showError("Unfortunately there has been an error with the download, please try again or fill out an enquiry?");
				}
			);
		} else {
			showError("No Downloads Selected.");
		}
		setTimeout(function () {
			$(progress_bar).addClass("hide");
			$(resultMessage).addClass("hide");
		}, 3000);
		return false;
	});

	// Clear Selection
	$(".clearSelected").on("click", function (event) {
		event.preventDefault();
		$("#download_form input[type=checkbox]").prop("checked", false);
		getChecked();
	});

	// Get Checked
	$("#download_form input[type=checkbox]").on("click", function (event) {
		getChecked();
	});
}
