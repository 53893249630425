export function productSlider() {
	if ($('.product_slider')[0]) {
		var galleryThumbs = new Swiper('.product_slider .gallery-thumbs', {
			spaceBetween: 10,
			slidesPerView: 2,
			loop: false,
			freeMode: true,
			loopedSlides: 0, //looped slides should be the same
			watchSlidesVisibility: true,
			watchSlidesProgress: true,
			breakpoints: {
				576: {
					slidesPerView: 3,
				},
			},
		});
		var galleryTop = new Swiper('.product_slider .gallery-top', {
			spaceBetween: 10,
			autoHeight: true, //enable auto height
			loop: false,
			loopedSlides: 0, //looped slides should be the same
			preventClicksPropagation: false,
			preventClicks: false,
			touchStartPreventDefault: false,
			allowTouchMove: false,
			navigation: {
				nextEl: '.product_slider .gallery-top .swiper-button-next',
				prevEl: '.product_slider .gallery-top .swiper-button-prev',
			},
			thumbs: {
				swiper: galleryThumbs.slides.length <= 0 ? false : galleryThumbs,
			},
		});
	}
}

export const latestNews = new Swiper('.latest_news_slider', {
	slidesPerView: 1,
	speed: 500,
	allowTouchMove: true,
	effect: 'fade',
	fadeEffect: {
		crossFade: true,
	},
	breakpoints: {
		992: {
			allowTouchMove: false,
		},
	},
	pagination: {
		el: '.latest_news_slider .swiper-pagination',
		clickable: true,
	},
	navigation: {
		nextEl: '.latest_news_slider .swiper-button-next',
		prevEl: '.latest_news_slider .swiper-button-prev',
	},
});

export const relatedProducts = new Swiper('.related_products_slider', {
	slidesPerView: 1,
	spaceBetween: 30,
	speed: 500,
	scrollbar: {
		el: '.related_products_slider .swiper-scrollbar',
		hide: false,
		draggable: true,
		snapOnRelease: true,
	},
	breakpoints: {
		576: {
			slidesPerView: 2,
		},
		992: {
			slidesPerView: 3,
			allowTouchMove: false,
		},
	},
});

export const keyProducts = new Swiper('.key_products_slider', {
	slidesPerView: 1,
	spaceBetween: 30,
	speed: 500,
	centerInsufficientSlides: true,
	scrollbar: {
		el: '.key_products_slider .swiper-scrollbar',
		hide: false,
		draggable: true,
		snapOnRelease: true,
	},
	breakpoints: {
		576: {
			slidesPerView: 2,
		},
		768: {
			slidesPerView: 3,
			allowTouchMove: false,
		},
		992: {
			slidesPerView: 4,
			allowTouchMove: false,
		},
	},
});

export const latestPosts = new Swiper('.latest_posts_slider', {
	slidesPerView: 1,
	spaceBetween: 30,
	speed: 500,
	navigation: {
		nextEl: '.latest_posts .swiper-button-next',
		prevEl: '.latest_posts .swiper-button-prev',
	},
	breakpoints: {
		576: {
			slidesPerView: 2,
		},
		992: {
			slidesPerView: 3,
			allowTouchMove: false,
		},
	},
});

export const iconSlider = new Swiper('.icon_slider', {
	slidesPerView: 1,
	spaceBetween: 20,
	autoplay: true,
	speed: 500,
	centerInsufficientSlides: true,
	navigation: {
		nextEl: '.icon_list .swiper-button-next',
		prevEl: '.icon_list .swiper-button-prev',
	},
	breakpoints: {
		375: {
			slidesPerView: 2,
		},
		576: {
			slidesPerView: 4,
		},
		992: {
			slidesPerView: 6,
			allowTouchMove: false,
		},
	},
});

export const customSolutionsSlider = new Swiper('.custom_solutions_slider', {
	slidesPerView: 1,
	spaceBetween: 0,
	autoHeight: true,
	scrollbar: {
		el: '.custom_solutions_slider .swiper-scrollbar',
		hide: false,
		draggable: true,
		snapOnRelease: true,
	},
});

export const lightbox = new Swiper('.lightbox-gallery', {
	slidesPerView: 1,
	spaceBetween: 30,
	speed: 500,
	navigation: {
		nextEl: '.lightbox-gallery .swiper-button-next',
		prevEl: '.lightbox-gallery .swiper-button-prev',
	},
	breakpoints: {
		576: {
			slidesPerView: 2,
		},
		992: {
			slidesPerView: 3,
			allowTouchMove: false,
		},
	},
});
