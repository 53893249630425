export const navigation = new hcOffcanvasNav('#menu', {
	disableAt: 992,
	customToggle: '.icon--hamburger',
	navTitle: false,
	levelSpacing: 0,
	levelTitles: false,
	levelTitleAsBack: false,
	bodyInsert: 'append',
	insertClose: true,
});

export function stickyNav() {
	if (window.pageYOffset > 40) {
		$('#h_nav').addClass('scroll_nav');
		$('#h_mobile').addClass('scroll_nav');
	} else {
		$('#h_nav').removeClass('scroll_nav');
		$('#h_mobile').removeClass('scroll_nav');
	}
}

export function navigationFunctions(navigation) {
	$('li.menu-item-has-children').on('mouseover', function (e) {
		$('#body_overlay').addClass('active');
	});

	$('li.menu-item-has-children').on('mouseout', function (e) {
		$('#body_overlay').removeClass('active');
	});
	navigation.on('toggle', (e, settings) => {
		$('.icon--hamburger').toggleClass('open');
	});
}
