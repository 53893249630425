// ADJUST POSITION TO SHOW ALL OF FORM
export function ctaSlideAdjust() {
	// CHECK THE WHOLE FORM IS VISIBLE
	const ctaForm = $('#contact_cta_marker');
	const formTop = ctaForm.offset().top;
	const formBottom = formTop + ctaForm.outerHeight();
	const viewportTop = $(window).scrollTop();
	const viewportBottom = viewportTop + $(window).height();

	if (formBottom > viewportTop && formTop < viewportBottom) {
		// DO NOTHING
	} else {
		$('html, body').animate(
			{
				scrollTop: viewportTop + (formBottom - viewportBottom),
			},
			200
		);
	}
}

// SET UP THE FORM TOGGLE
export function initCtaFunctions() {
	$(document).on('click', 'a.contact_cta_toggle', function (e) {
		e.preventDefault();

		const $this = $('#contact_cta_wrapper');

		if ($this.hasClass('active')) {
			$this.removeClass('active');
			$this.slideUp(200);
		} else {
			$this.addClass('active');
			$this.slideDown(200, function () {
				ctaSlideAdjust();
			});
		}
	});
}

// Product Page Read More
export function productReadMore() {
	$('.toggle_more').on('click', function (event) {
		event.preventDefault();

		$(this).children('.plus').toggleClass('d-none');
		$(this).children('.minus').toggleClass('d-none');

		$('.readmore').slideToggle('fast');
	});
}

// Toggle Home contact panel
export function contactToggle() {
	$('.contact-element .toggle').on('click', function (event) {
		event.preventDefault();
		$('.contact-panel .contactForm').toggleClass('active');
	});
}

export function displayFormToggle() {
	$('.toggleForm').on('click', function (event) {
		event.preventDefault();
		$('.contactForm .gravityFormsWrapper').slideToggle('fast');
		$(this).slideUp('fast');
	});
}
