import { isElementInViewport } from "../common/viewport";

export function homepageFormVisible() {
	var contactElement = document.getElementsByClassName("contact-element");
	if (contactElement[0]) {
		if (isElementInViewport(contactElement[0])) {
			$("#cta_banner").removeClass("visible");
		} else {
			$("#cta_banner").addClass("visible");
		}
	}
}
