export function initHeroSlider() {
	if ($(".hero_swiper")[0]) {
		var swiperData = $("#hero_main .hero_main_slider").data("swiper");
		var noHeroSlides = $("#hero_main .hero_main_slider .swiper-slide").length;
		var params = {
			lazy: true,
			speed: swiperData ? parseInt(swiperData.speed) : 1500,
			loop: noHeroSlides == 1 ? false : true,
			allowTouchMove: true,
			simulateTouch: false,
			breakpoints: {
				992: {
					allowTouchMove: noHeroSlides == 1 ? false : true,
				},
			},
		};

		$.extend(params, {
			pagination: {
				el: "#hero_main .swiper-pagination",
				clickable: true,
			},
		});

		if (swiperData) {
			if (swiperData.fade) {
				$.extend(params, {
					effect: "fade",
					fadeEffect: {
						crossFade: true,
					},
				});
			}

			if (swiperData.autoplay && noHeroSlides > 1) {
				$.extend(params, {
					autoplay: {
						delay: swiperData.autoplaySpeed,
						disableOnInteraction: true,
					},
				});
			}
		}

		var heroSlider = new Swiper("#hero_main .hero_main_slider", params);
	}
}
