export function searchFilters() {
	//setup before functions
	var typingTimer; //timer identifier
	var doneTypingInterval = 500;
	var $input = $('#searchTerm');
	var $loading = $('#searchModal .form--loader');
	var $results = $('#filterResults');

	//on keyup, start the countdown
	$input.on('keyup', function () {
		clearTimeout(typingTimer);
		typingTimer = setTimeout(doneTyping, doneTypingInterval);
	});

	//on keydown, clear the countdown
	$input.on('keydown', function () {
		clearTimeout(typingTimer);
	});

	//user is "finished typing," do something
	function doneTyping() {
		$loading.show();
		$input.prop('disabled', true);
		var searchTerm = $input.val();

		var data = {
			action: 'searchfilter',
			searchTerm: searchTerm,
		};

		$.ajax({
			url: '/core/wp-admin/admin-ajax.php',
			data: data,
			type: 'POST',
			dataType: 'json',
			success: function (results) {
				if (results) {
					$loading.hide();
					$('#filterResults').html(results.filterResults);
					$results.show();
					$input.prop('disabled', false);
				}
			},
		});
	}
}
